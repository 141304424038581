/*.table-responsive {
    overflow-x: auto;
    width: 100%;
}*/

.relative-position {
  border: 1px solid #eceef1;
  border-radius: 10px;
  text-align: center;
}
.btn-casse {
  color: #fff;
  background-color: #e6831a;
  border-color: #e6831a;
  transform: translateY(-1px);
}
